<template>
  <Authenticated>
    <VouchersModal :show="accountShowModal" :account="currentAccount" @save="saveAccount" />
    <div>
      <button @click="showAccount(currentAccount)" class="action-buttons"><font-awesome-icon icon="plus" /> Add Voucher</button>
      <div class="actions">
        <h2 @click="getAccounts">Vouchers</h2>
      </div>

      <div class="search-sort-layout">
        <form role="search" class="search">
          <input id="search" type="search" placeholder="Search..." autofocus required v-model="searchQuery" />
          <!-- <button @click="search()" type="submit">Go</button> -->
        </form>
        <div class="sort-dropdown">
          <h2>Sort:</h2>
          <select v-model="sortOption">
            <option value="" disabled selected >Default</option>
            <option value="name">Name</option>
            <option value="id">ID</option>
            <option value="htl">Balance(High to Low)</option>
            <option value="lth">Balance(Low to High)</option>
          </select>
        </div>
      </div>
      <h2 v-if="noResults">No Vouchers Found</h2>
      <h2 v-if="searchQuery && searches.length">Searches:</h2>
      <ul v-if="searches.length" class="table wrapper layout content-gap">
        <template v-bind:key="search" v-for="search in searches">
          <li @click="showAccount(search)" class="modifierBox">
            <div class="box">
              <p>
                <span class="modifier-name">{{ search.key[1] }}</span>
                <span class="modifier-name">{{ search.key[0] }}</span>
                <span class="modifier-name">${{ search.value }}</span>
              </p>
              <span></span>
            </div>
          </li>
        </template>
      </ul>

      <ul class="table wrapper layout content-gap">
        <template v-bind:key="account" v-for="account in accounts">
          <li @click="showAccount(account)" class="modifierBox">
            <div class="box">
              <p>
                <span class="modifier-name">{{ account.key[1] }}</span>
                <span class="modifier-name">{{ account.key[0] }}</span>
                <span class="modifier-name">${{ account.value }}</span>
              </p>
              <span></span>
            </div>
          </li>
        </template>
      </ul>
    </div>

  </Authenticated>
</template>
<script>
import Authenticated from "@/components/_layouts/Authenticated";
import { mapGetters } from "vuex";
import createdMixin from "@/components/_mixins/createdMixin";
import VouchersModal from "@/components/Vouchers/VouchersModal";
import { api } from "@/helpers/api";

export default {
  name: "Accounts",
  mixins: [createdMixin],
  components: {
    Authenticated,
    VouchersModal,
  },
  data() {
    return {
      channel: null,
      currentAccount: {},
      showAddAccount: false,
      accountRoute: false,
      showAccountModal: false,
      searchQuery: "",
      searches: [],
      noResults: false,
      sortOption: "",
      sortedAccounts: [],
    };
  },
  mounted() {
    if (this.$route.params.account) {
      this.accountRoute = true;
    }
    this.$store.commit("currPage", "vouchers");
  },
  computed: {
    accountShowModal() {
      //console.log("here");
      if (this.accountRoute) {
        return true;
      } else {
        return this.showAccountModal;
      }
    },
    ...mapGetters({
      modifier_sets: "modifier_sets",
      accounts: "vouchers",
    }),
  },
  watch: {
    sortOption: function (val) {
      console.log('here')
      if(val === "name"){
        this.accounts.sort((a, b) => {
          return a.key[1].localeCompare(b.key[1]);
        });
      }
      else if(val === "htl"){
        this.accounts.sort((a, b) => {
          return b.value - a.value;
        });
      }
      else if(val === "lth"){
        this.accounts.sort((a, b) => {
          return a.value - b.value;
        });
      }else if(val === "id"){
        this.accounts.sort((a, b) => {
          return a.key[0] - b.key[0];
        });
      }
    },
    searchQuery: function () {
      this.search()
    },
  },
  methods: {
    async search() {
      if (this.searchQuery.length > 1) {
        const search = await api.searchVouchers(this.searchQuery);
        let result = search.rows.map((acc) => (acc.key[2] == "id-name" ? { key: [acc.key[0], acc.key[1]], value: acc.value } : { key: [acc.key[1], acc.key[0]], value: acc.value }));
        //console.log(result);
        !result.length ? this.noResults = true : this.noResults = false; 
        this.searches = result;
        document.querySelector(".sort-dropdown").style.display = "none";
      }else{
        this.noResults = false;
        this.searches = [];
        document.querySelector(".sort-dropdown").style.display = "flex";
      }
    },
    accountHistory(account) {
      console.log(account);
      this.$store.dispatch("voucherHistory", account);
      //this.channel.push("account:history", id, name);
    },
    showAccount(account) {
      if (account.key) {
        this.accountHistory(account.key);
        this.currentAccount = {
          ID: account.key[0],
          name: account.key[1],
          value: account.value,
          new: true,
        };
      } else {
        this.currentAccount = {
          ID: "",
          name: "",
          doc_type: "credit",
          value: 0,
          date: new Date().toISOString(),
        };
      }

      this.showAccountModal = !this.showAccountModal;
    },
    saveAccount() {
      let voucher = {_id: "product:vouchers" + this.currentAccount.ID, id: "product:vouchers" + this.currentAccount.ID, modifiers: {}, price: this.currentAccount.value, hash: "", name: "voucher-" + this.currentAccount.ID, value: {_id: "product:vouchers" + this.currentAccount.ID, name: "product:vouchers" + this.currentAccount.ID}};
      console.log(voucher);
      this.$store.dispatch("addProduct", voucher);
      this.$store.dispatch("addToCart", voucher);
      this.channel.push("voucher:doc", this.currentAccount);
    },
  },
};
</script>

<style scoped lang="scss">
@import "public/layout";
@import "public/actions";
@import "public/wrapper";
@import "public/toggle";
@import "public/search";

h2{
  margin: 1rem;
}

.search-sort-layout {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem 0 0;

  .fa-sort {
    font-size: 2rem;
  }
  .sort-dropdown {
    display: flex;
    align-items: center;
    select {
      height: 3rem;
      font-size: 1.5rem;
      padding: 0.5rem;
      border-radius: 0.5rem;
      border: 1px solid var(--text-colour);
      background-color: var(--text-colour);

    }
  }
}

@media only screen and (orientation: portrait) {
  .search-sort-layout {
    flex-direction: column;
    align-items: flex-start;
    padding: 0 0 0 1rem;

    .sort-dropdown{
      h2{
        min-width:0rem;
      }
      
    }
  
  }

}

</style>
